<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-stamper</v-icon>
        Hazirun Listesi
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit('pdf')" ref="form">
          <v-row dense>
            <v-col cols="12">
              <rs-text-field label="Belge Başlığı" v-model="formData.title" />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select-block
                multiple
                v-model="filters.block"
                clearable
              ></rs-select-block>
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                label="Oturum Şekli"
                v-model="filters.house_user_type"
                multiple
                :items="houseUserTypeList"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                :label="$t('labels.house_type')"
                v-model="filters.house_type"
                multiple
                :items="houseTypes"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                :label="$t('labels.is_active')"
                v-model="filters.is_active"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <rs-select
                label="Seçenekli Alanlar"
                v-model="selectedFields"
                :items="fieldTypeList"
                multiple
                required
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col sm="4">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
              >
                Göster
              </v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ms-2"
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    İndir
                    <v-icon>mdi-menu-down-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleDownloadClick('odt')">
                    <v-list-item-title>
                      <v-icon>mdi-table</v-icon> ODT
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('docx')">
                    <v-list-item-title>
                      <v-icon>mdi-table</v-icon> DOCX
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('pdf')">
                    <v-list-item-title>
                      <v-icon>mdi-text-box-outline</v-icon> PDF
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm } from "@/view/mixins";
import { slugify } from "@/core/helpers";

export default {
  mixins: [hasDataTable, hasForm],
  computed: {
    ...mapGetters(["cluster", "clusterId", "houseTypes", "houseUserTypeList"]),
  },
  watch: {
    clusterId() {
      this.iframeUrl = null;
    },
  },
  mounted() {
    this.formData.title =
      new Date().toLocaleDateString() +
      " Tarihli Olağan Toplantı Hazirun Listesi";
  },
  data() {
    return {
      iframeUrl: null,
      reportData: null,
      filters: {
        block: [],
        is_active: true,
        house_type: [],
        house_user_type: [1],
      },
      formData: {
        title: "",
      },
      selectedFields: ["identity_number", "email", "phone", "address_legal"],
      fieldTypeList: [
        {
          id: "identity_number",
          name: "TC Kimlik No",
        },
        {
          id: "phone",
          name: "Telefon",
        },
        {
          id: "email",
          name: "E-posta",
        },
        {
          id: "address_legal",
          name: "Adres",
        },
      ],
    };
  },
  methods: {
    handleFormSubmit(format, download) {
      format = format || "pdf";
      if (this.isLoading || !this.$refs.form.validate() || !this.clusterId) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const params = Object.assign(this.buildSearchParams(this.filters));
      params.per_page = 2000;
      params.order_by = "house_name";
      this.iframeUrl = null;
      this.isLoading = true;

      return this.$api
        .query(`clusters/${this.clusterId}/residents`, { params })
        .then((response) => {
          this.reportData = {
            list: [],
            title: this.formData.title,
          };
          let orderNum = 0;
          this.reportData.list = response.data.data.map((v) => {
            orderNum++;

            const row = {
              order_num: orderNum,
              name: v.name,
              block_name: v.block || v.block_name,
              door_number: v.door_number,
              house_name: (v.block || v.block_name) + "-" + v.door_number,
              house_user_type_name: v.house_user_type || v.house_user_type_name,
              house_type_name: v.house_type || v.house_type_name,
              house_count: v.house_count,
            };

            if (this.selectedFields.indexOf("identity_number") !== -1) {
              row.address_legal = v.address_legal;
            }

            if (this.selectedFields.indexOf("phone") !== -1) {
              row.phone = v.phone;
            }

            if (this.selectedFields.indexOf("email") !== -1) {
              row.email = v.email;
            }

            if (this.selectedFields.indexOf("identity_number") !== -1) {
              row.identity_number = v.identity_number;
            }

            return row;
          });
          this.reportData.cluster = this.cluster.name;
          // for (const index in this.formData) {
          //   if (response.data.data[index] !== undefined) {
          //     this.reportData[index] = response.data.data[index];
          //   }
          // }

          return this.reportData;
        })
        .then((reportData) => {
          let fileName = this.cluster.name + "-";
          fileName += new Date().toLocaleDateString();
          fileName += "-HazirunListesi";

          if (fileName.length >= 100) {
            fileName = fileName.substring(0, 99);
          }

          const params = {
            file_type: format,
            print_template: "MeetingAttendants",
            receiptData: reportData,
            file_name: slugify(fileName),
          };

          return this.$api.post("print-file", params).then((response) => {
            if (format !== "pdf" || download) {
              const anchor = document.createElement("a");
              anchor.href = response.data.data.url;
              anchor.target = "_blank";
              anchor.click();
            } else {
              this.iframeUrl = response.data.data.url;
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadClick(format) {
      this.handleFormSubmit(format || "pdf", true);
    },
  },
  // mounted() {
  // },
};
</script>
